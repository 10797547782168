import React, { useEffect, useState } from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"
import { graphql } from "gatsby"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Span from "@components/elements/span"
import Button from "@components/elements/button"
import { useGradient } from "@hooks/use-gradient"
import { HighGradient } from "@components/common/gradient"
import Image from "@components/image"
import Paragraph from "@components/elements/paragraph"
import { formatDate } from "@utils/format-date"

const StackedAvatars = ({ companies }) => {
  const { shadow } = useTheme()

  return (
    <Flex flexFlow="row">
      {companies.map((company, index) => (
        <Flex
          margin={index === 0 ? "0" : "0 0 0 0.5rem"}
          borderRadius="50%"
          height={"40px"}
          width={"40px"}
          overflow="hidden"
          key={company.id}
          zIndex={index}
          boxShadow={shadow.default}
          style={{ border: "1px solid white" }}
          alignItems="center"
          justifyContent="center"
          background="white"
          alt={company.name}
          title={company.name}
        >
          <Image
            width={24}
            height={24}
            objectFit="scale-down"
            file={company.logo}
            alt={company.name}
          />
        </Flex>
      ))}
    </Flex>
  )
}

StackedAvatars.propTypes = {
  companies: PropTypes.array.isRequired,
}

const WebinarView = ({ webinars, past }) => {
  const { color, shadow, gradient } = useTheme()

  return (
    <Grid gap="2rem" gapSm="1rem" columns="repeat(2, 1fr)" columnsMd="1fr">
      {webinars.map(event => {
        const webinar = event.node ?? event // page sorting vs context
        return (
          <Flex
            key={`webinar-${webinar.id}`}
            background={"rgba(255,255,255,0.2)"}
            padding="2rem"
            borderRadius="1rem"
            boxShadow={shadow.light}
          >
            {past ? (
              <Span fontWeight={600}>On-Demand</Span>
            ) : (
              <Span>{formatDate(webinar.date)}</Span>
            )}
            <Heading as="h3" margin="0.5rem 0 1rem">
              {webinar.seo.title}
            </Heading>
            <Paragraph
              lineHeight={1.6}
              margin="0 0 2.5rem"
              marginSm="0 0 1.5rem"
            >
              {webinar.seo.description}
            </Paragraph>
            <Flex
              flexFlow="row"
              justifyContent="space-between"
              alignItems="flex-end"
              margin="auto 0 0"
            >
              {past ? (
                <Button
                  href={`/resources/webinars/${webinar.slug}`}
                  notice={false}
                  background={gradient.powerMetricsButtonAlt}
                  boxShadow="0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
                  color={color.indigo600}
                >
                  Watch on-demand
                </Button>
              ) : (
                <Button
                  href={`/resources/webinars/${webinar.slug}`}
                  notice={false}
                  background={gradient.powerMetricsButtonAlt}
                  boxShadow="0px 8px 16px rgba(75, 87, 197, 0.2), inset 0px -6px 14px rgba(255, 255, 255, 0.4)"
                  color={color.indigo600}
                >
                  Register Now
                </Button>
              )}
              <Flex hideOnSm gap="0.5rem" alignItems="flex-start">
                <Span fontWeight={600} fontSize={"0.8rem"}>
                  Presented by:
                </Span>
                <StackedAvatars companies={webinar.companies} />
              </Flex>
            </Flex>
          </Flex>
        )
      })}
    </Grid>
  )
}

WebinarView.propTypes = {
  webinars: PropTypes.array.isRequired,
  past: PropTypes.bool,
}

const Webinars = ({ data, pageContext }) => {
  const webinars = data.webinars.edges
  const { color } = useTheme()
  const { gradient } = useGradient()

  const [upcoming, setUpcoming] = useState(pageContext.upcoming)
  const [past, setPast] = useState(pageContext.past)

  useEffect(() => {
    const upcomingWebinars = webinars.filter(
      webinar => new Date(webinar.node.date) > new Date()
    )
    setUpcoming(upcomingWebinars)

    const pastWebinars = webinars.filter(
      webinar => new Date(webinar.node.date) < new Date()
    )
    setPast(pastWebinars)
  }, [])

  return (
    <Layout
      title={`Webinars`}
      description={`Discover how to succeed with data by joining a live webinar or watching one on demand.`}
      fullWidth
      marginless
      hideOverflow
      canonical={pageContext.slug}
    >
      <Div background={color.indigo100}>
        <Div
          width="100%"
          margin="10rem auto 6rem"
          marginSm="8rem auto 4rem"
          container
          position="relative"
        >
          <HighGradient src={gradient.cdn} />
          <Div position="relative">
            <Heading margin="0 auto 1rem" center as="h1">
              Klipfolio Webinars
            </Heading>

            <Flex gap="4rem" gapSm="3rem">
              <WebinarView webinars={upcoming} />
              <WebinarView webinars={past} past />
            </Flex>
          </Div>
        </Div>
      </Div>
    </Layout>
  )
}

Webinars.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query WebinarsQuery {
    webinars: allWebinars {
      edges {
        node {
          id
          date
          seo {
            title
            description
          }
          slug
          presenters {
            id
            headshot {
              cdn
              placeholder
              id
              directus_id
            }
          }
          companies {
            id
            name
            logo {
              cdn
              placeholder
              id
              directus_id
            }
          }
        }
      }
    }
  }
`

export default Webinars
